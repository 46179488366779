
import { Outlet } from '@remix-run/react';
import clsx from 'clsx';
import SuperfdLogo from '#app/assets/logo2.png';
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx';
import styles from '#app/styles/index.module.css';

export default function AuthLayoutRoute() {

  return (
    <>
	  
	  <div className="container lg:border bg-background shadow rounded flex flex-col justify-center py-10 px-0 md:p-0 md:pb-36 mt-10">

      <div className="container lg:h-[800px] flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="hidden relative h-full dark:border-r lg:block">
		    <div
              className={clsx(
                'absolute inset-0 bg-bottom bg-no-repeat bg-slate-50 dark:bg-[#0B1120]',
                styles.beams
              )}>

          <div
                className="absolute inset-0 bg-grid-slate-900/[0.04] bg-[bottom_1px_center] dark:bg-grid-slate-400/[0.05] dark:bg-bottom dark:border-b dark:border-slate-100/5"
                style={{
                  maskImage: 'linear-gradient(to bottom, transparent, black)',
                  WebkitMaskImage: 'linear-gradient(to bottom, transparent, black)'
                }} />

        </div>
        <div className="h-full flex flex-col p-10 ">

        {/* <div className="relative z-20 flex items-center text-lg font-medium">
                Super FD
                </div> */}
        
        <div className="z-50 mt-60 w-[200px] h-[100px] p-4 rounded-lg bg-gray-500/10 flex justify-center items-center">
            <img src={SuperfdLogo} alt="superfd" className="object-cover" />
        </div>

        <div className="z-20 my-4 text-2xl font-semibold">
          <div>Earn <span className="text-gain">decade-high returns (9%+)</span></div>
          <div>on all your FDs!</div>
        </div>
        
        

        </div>
        </div>
        <div className="lg:p-8">
            <Outlet />
        </div>
      </div>
	  </div>
    </>);

}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}